import * as React from "react"
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { StoreContext } from "../../context/store-context"
import { Layout } from "../../components/Layout/layout"


const Form = styled.form`
  text-align: center;
`

const FormInner = styled.div`
  display: inline-block;
  width: 500px;
`

const Input = styled.input` 
  display: block;
  border: 1px solid grey;
  margin: 0 0 20px;
  width: 100%;
`

export default function CreatePage() {
  const { customerCreate, loading } = React.useContext(StoreContext);
  const { register, handleSubmit } = useForm();
  const onSubmit = data => customerCreate(data)  //prints {"firstName":"something","gender":"male"}
  return (
    <Layout>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInner>
        <Input name="email" {...register("email")}/>
        <Input name="password" type="password" {...register("password")}/>
        <Input name="phone" type="number" {...register("phone")}/>
        <Input name="firstName" {...register("firstName")}/>
        <Input name="lastName" {...register("lastName")}/>
        <input type="submit" />
        </FormInner>
      </Form>
    </Layout>
  )
}
